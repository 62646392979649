<script setup lang="ts">
interface Props {
  windowOpen?: boolean;
  iframeUrl: string;
}

withDefaults(defineProps<Props>(), {
  windowOpen: false,
});

interface Emits {
  (e: 'close'): void;
}

const emit = defineEmits<Emits>();

function closeWindow() {
  emit('close');
}
</script>
<template>
  <transition name="grow" appear>
    <div
      id="deals-window"
      class="absolute bottom-0 h-full w-full drop-shadow-lg"
    >
      <button
        id="deals-window-close-btn"
        class="absolute right-[9px] top-[6px] p-1 text-white hover:text-gray-200"
        @click="closeWindow"
      >
        <SparkIcon icon="close" size="18"></SparkIcon>
      </button>
      <iframe
        ref="basis-deals-bubble-iframe"
        :src="iframeUrl"
        class="h-full w-full"
      ></iframe>
    </div>
  </transition>
</template>

<style scoped></style>
<style lang="scss" scoped>
.fastFade-enter-active {
  @apply h-[50%] opacity-0 transition-all;
}

.fastFade-enter-to {
  @apply h-[100%] opacity-100;
}

.fastFade-leave-active {
  @apply opacity-100;
}

.fastFade-leave-to {
  @apply opacity-0 transition-all;
}
</style>
