import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { webhookService } from '@/products/freight/services';
import type { WebhookDTO } from '@/types';

const useWebhookStore = defineStore('webhook', () => {
  // state
  const error = ref<string>('');
  const isLoading = ref<boolean>(false);
  const webhooks = ref<WebhookDTO[]>([]);
  const contracts = ref<string[]>([]);
  const eventTypes = ref<string[]>([]);

  // getters
  const selectedWebhook = computed(() => {
    return (uuid: string) => {
      return webhooks.value?.find((v) => v.uuid === uuid);
    };
  });

  // actions
  function setWebhooksMeta(meta: {
    contracts: string[];
    eventTypes: string[];
  }) {
    contracts.value = meta.contracts;
    eventTypes.value = meta.eventTypes;
  }

  function updateWebhookState(webhook: Partial<WebhookDTO>) {
    const existingWebhook = webhooks.value.find((v) => v.uuid === webhook.uuid);
    if (existingWebhook) {
      // make deliveries reactive when available
      existingWebhook.deliveries = existingWebhook?.deliveries ?? [];
      //   webhook.deliveries[0].attempts = webhook.deliveries[0].attempts?.concat(
      //     webhook.deliveries[0].attempts
      //   );
      Object.assign(existingWebhook, webhook);
    }
  }

  function removeWebhook(uuid: string) {
    webhooks.value = webhooks.value.filter((v) => v.uuid !== uuid);
  }

  async function getWebhooks() {
    try {
      isLoading.value = true;
      error.value = '';
      const response = await webhookService.getWebhooks();
      if (!response.errors?.length) {
        webhooks.value = response?.data;
      } else {
        error.value = 'An error has occurred. Please try again later';
      }
    } catch (e) {
      error.value = 'An error has occurred. Please try again later';
    } finally {
      isLoading.value = false;
    }
  }
  async function getWebhooksMeta() {
    try {
      const response: any = await webhookService.getWebhooksMeta();
      if (!response.errors?.length) {
        setWebhooksMeta(response?.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function deleteWebhook(uuid: string) {
    try {
      error.value = '';
      const response: any = await webhookService.deleteWebhook(uuid);
      if (!response.errors?.length) {
        removeWebhook(uuid);
      } else {
        error.value = 'An error has occurred. Please try again later';
      }
    } catch (e) {
      error.value = 'An error has occurred. Please try again later';
    }
  }

  async function addWebhook({
    url,
    description,
    contracts,
    eventTypes,
  }: {
    url: string;
    description: string;
    contracts: string[];
    eventTypes: string[];
  }) {
    try {
      error.value = '';
      const response: any = await webhookService.createWebhook({
        url,
        description,
        contracts,
        eventTypes,
      });
      if (!response?.errors?.length) {
        webhooks.value = [response.data, ...webhooks.value];
      } else {
        error.value = 'An error has occurred. Please try again later';
      }
    } catch (e) {
      error.value = 'An error has occurred. Please try again later';
    }
  }

  async function updateWebhook(webhook: Partial<WebhookDTO>) {
    try {
      error.value = '';
      const response: any = await webhookService.updateWebhook(webhook);
      if (!response.errors?.length) {
        updateWebhookState(response.data);
      } else {
        error.value = 'An error has occurred. Please try again later';
      }
    } catch (e) {
      error.value = 'An error has occurred. Please try again later';
    }
  }

  async function resetSigningSecret(uuid: string) {
    try {
      error.value = '';
      const response: any = await webhookService.resetSigningSecret(uuid);
      if (!response.errors?.length) {
        updateWebhookState(response.data);
      } else {
        error.value = 'An error has occurred. Please try again later';
      }
    } catch (e) {
      error.value = 'An error has occurred. Please try again later';
    }
  }

  async function getDeliveries(uuid: string) {
    try {
      error.value = '';
      const response: any = await webhookService.getDeliveries(uuid);
      if (!response.errors?.length) {
        updateWebhookState({ uuid, deliveries: response.data });
      } else {
        error.value = 'An error has occurred. Please try again later';
      }
    } catch (e) {
      error.value = 'An error has occurred. Please try again later';
    }
  }

  async function triggerWebhook({
    uuid,
    contract,
    eventType,
  }: {
    uuid: string;
    contract: string;
    eventType: string;
  }) {
    try {
      error.value = '';
      const response: any = await webhookService.triggerWebhook(
        uuid,
        contract,
        eventType,
      );
      if (!response.error?.length) {
        return true;
      } else {
        error.value = 'An error has occurred. Please try again later';
      }
    } catch (e) {
      error.value = 'An error has occurred. Please try again later';
    }
  }

  return {
    // state
    error,
    isLoading,
    webhooks,
    contracts,
    eventTypes,

    // getters
    selectedWebhook,

    // actions
    setWebhooksMeta,
    updateWebhookState,
    removeWebhook,
    getWebhooks,
    getWebhooksMeta,
    deleteWebhook,
    addWebhook,
    updateWebhook,
    resetSigningSecret,
    getDeliveries,
    triggerWebhook,
  };
});

export { useWebhookStore };
